@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Coolvetica';
    src: local("Coolvetica"),
    local("Coolvetica"),
    url(./assets/fonts/coolvetica/coolvetica\ compressed\ hv.otf);
    font-weight: bold;
}


html{
    font-size : 100%;
    @apply scroll-smooth;
}

html::-webkit-scrollbar{
    @apply  w-2 rounded-sm bg-primary bg-opacity-50;
}
html::-webkit-scrollbar-thumb{
    @apply absolute w-1 rounded-md bg-secondary bg-opacity-80;
}
*, *::after{
    @apply transition-all duration-200 appearance-none outline-none;
}
body{
    @apply bg-primary bg-opacity-10;
}